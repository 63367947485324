import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import HeroSection from "../components/HeroSection"
import NavBar from "../components/NavBar"
import vending from "../images/vending.jpg"

export default function About(props) {
    return (
        <Layout>
            <SEO
                title="404 Error"
            />
            <NavBar />
            <HeroSection
                img={vending}
                order="order-first"
                title="404 Error - Page Not Found"
                cta="Go Back"
                link="/"
            />
            <Footer />
        </Layout>
    )
}
